<template>
  <base-view title="Bitácoras">
    <div class="container-fluid mt-4">
      <History />
    </div>
  </base-view>
</template>

<script>
import History from '@/components/History/History'

export default {
  name: 'HistoryView',

  components: {
    History
  }
}
</script>
